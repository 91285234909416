import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import axios from "axios";
import { BackgroundMode } from "@awesome-cordova-plugins/background-mode";

export interface State {
    viewWheel: any,
    viewWheelSections: any,
    wheel_sections: Array<unknown>,
    wheel: any,
    started: boolean,
    timer: number,
    timeLeft: number,
    timestamp: number,
    timerSpeed: number
    headsUpPercent: number,
    currentsectionIndex: number,
    beep: HTMLAudioElement,
    voice: HTMLAudioElement,
    music: HTMLAudioElement,
    headsUp: HTMLAudioElement,
    wheelEnd: HTMLAudioElement,
    silent: string,
    audioUrl: string,
    shouldPlayVoice: boolean,
    shouldPlayMusic: boolean,
    shouldPlayHeadsUp: boolean,
    voiceVolume: number,
    musicVolume: number,
    headUpVolume: number,
    headsUpPlayed: boolean,
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        viewWheel: null,
        viewWheelSections: [],
        wheel_sections: [],
        wheel: null,
        started: false,
        timer: 0,
        timeLeft: 0,
        timestamp: 0,
        timerSpeed: localStorage.timerSpeed ? parseInt(localStorage.timerSpeed) : 1,
        headsUpPercent: localStorage.headsUpPercent ? parseInt(localStorage.headsUpPercent) : 20,
        currentsectionIndex: 0,
        beep: new Audio(),
        voice: new Audio(),
        music: new Audio(),
        headsUp: new Audio(),
        wheelEnd: new Audio(),
        silent: "data:audio/wav;base64,UklGRjIAAABXQVZFZm10IBIAAAABAAEAQB8AAEAfAAABAAgAAABmYWN0BAAAAAAAAABkYXRhAAAAAA==",
        audioUrl: "https://api.prayerwheel.coveredministries.com/audio",
        shouldPlayVoice: localStorage.voice != "false",
        shouldPlayMusic: localStorage.music != "false",
        shouldPlayHeadsUp: localStorage.headsUp != "false",
        voiceVolume: localStorage.voiceVolume ? parseInt(localStorage.voiceVolume) : 100,
        musicVolume: localStorage.musicVolume ? parseInt(localStorage.musicVolume) : 100,
        headUpVolume: localStorage.musicVolume ? parseInt(localStorage.headsUpVolume) : 100,
        headsUpPlayed: false
    },
    getters: {
        currentsection(state) {
            return state.wheel_sections[state.currentsectionIndex]
        },
        isViewing(state) {
            return state.viewWheel != null && state.viewWheelSections.length > 0
        }
    },
    mutations: {
        setViewingWheel(state, wheel) {
            state.viewWheel = wheel
        },
        setViewingSections(state, sections) {
            state.viewWheelSections = sections
        },
        setWheel(state, wheel) {
            state.wheel = wheel
        },
        setWheelSections(state, sections) {
            state.wheel_sections = sections
        },
        setStarted(state, started) {
            state.started = started
        },
        setTime(state, time) {
            state.timeLeft = time
        },
        decreaseTime(state, time) {
            state.timeLeft -= time
        },
        setTimestamp(state, timestamp) {
            state.timestamp = timestamp
        },
        setTimerSpeed(state, speed) {
            localStorage.timerSpeed = speed
            state.timerSpeed = speed
        },
        setHeadsUpPercent(state, percent) {
            localStorage.headsUpPercent = percent
            state.headsUpPercent = percent
        },
        setTimer(state, callback) {
            state.timer = setInterval(callback, 1000)
        },
        clearTimer(state) {
            clearInterval(state.timer)
        },
        incrementCurrent(state, index = null) {
            if (index || index === 0) state.currentsectionIndex = index
            else state.currentsectionIndex++
        },
        initAudio(state) {
            state.music.src = state.silent;
            state.voice.src = state.silent;
            state.beep.src = state.silent;
            state.headsUp.src = state.silent;
            state.wheelEnd.src = state.silent
            state.voice.autoplay = true;
            state.voice.volume = state.shouldPlayVoice ? state.voiceVolume * 0.01 : 0;
            state.beep.autoplay = true;
            state.music.autoplay = true;
            state.music.volume = state.shouldPlayMusic ? state.musicVolume * 0.01 : 0;
            state.headsUp.autoplay = true;
            state.headsUp.volume = state.shouldPlayMusic ? state.musicVolume * 0.01 : 0;
            state.wheelEnd.autoplay = true;
            state.wheelEnd.volume = state.shouldPlayMusic ? state.musicVolume * 0.01 : 0;
        },
        setAudio(state, { name, src }) {
            switch (name) {
                case "voice":
                    state.voice.src = src
                    break;
                case "music":
                    state.music.src = src
                    break
                case "headsUp":
                    state.headsUp.src = src
                    break
                case "beep":
                    state.beep.src = src
                    break
                case "wheelEnd":
                    state.wheelEnd.src = src
                    break
            }
        },
        setAudioEnd(state, { name, callback }) {
            switch (name) {
                case "voice":
                    state.voice.onended = callback
                    break;
                case "music":
                    state.music.onended = callback
                    break
                case "headsUp":
                    state.headsUp.onended = callback
                    break
                case "wheelEnd":
                    state.wheelEnd.onended = callback
                    break
            }

        },
        setAudioVolume(state, { name, volume }) {
            switch (name) {
                case "voice":
                    state.voiceVolume = volume
                    state.voice.volume = volume * .01
                    break;
                case "music":
                    state.musicVolume = volume
                    state.music.volume = volume * .01
                    state.wheelEnd.volume = volume * .01
                    break;
                case "headsUp":
                    state.headUpVolume = volume
                    state.headsUp.volume = volume * .01
                    break;
                case "wheelEnd":
                    state.musicVolume = volume
                    state.wheelEnd.volume = volume * .01
                    break;
            }
        },
        setShouldPlayVoice(state, should) {
            if (should) state.voice.volume = state.voiceVolume * .01
            else state.voice.volume = 0
            state.shouldPlayVoice = should
        },
        setShouldPlayMusic(state, should) {
            if (should) state.music.volume = state.musicVolume * .01
            else state.music.volume = 0
            state.shouldPlayMusic = should
        },
        setShouldPlayHeadsUp(state, should) {
            if (should) state.headsUp.volume = state.headUpVolume * .01
            else state.headsUp.volume = 0
            state.shouldPlayHeadsUp = should
        },
        pauseAudio(state) {
            state.voice.pause()
            state.music.pause()
            state.beep.pause()
        },
        resumeAudio(state) {
            state.voice.play()
            state.music.play()
            state.beep.play()
        },
        stop(state) {
            clearInterval(state.timer)
            state.wheel_sections = []
            state.wheel = null
            state.started = false
            state.timer = 0
            state.timeLeft = 0
            state.timestamp = 0
            state.currentsectionIndex = 0
            state.beep.pause()
            state.beep.remove()
            state.beep = new Audio()
            state.voice.pause()
            state.voice.remove()
            state.voice = new Audio()
            state.music.pause()
            state.music.remove()
            state.music = new Audio()
        },
        setHeadsUpPlayed(state, played) {
            state.headsUpPlayed = played
        }
    },
    actions: {
        async fetchWheel({ commit }, wheel_id) {
            await axios.get(`https://api.prayerwheel.coveredministries.com/wheels/${wheel_id}`)
                .then((res) => (commit("setViewingWheel", res.data)))
                .catch((err) => console.log(err));
        },
        async fetchWheelSections({ commit }, wheel_id) {
            await axios.get(`https://api.prayerwheel.coveredministries.com/wheels/${wheel_id}/sections`)
                .then((res) => (commit("setViewingSections", res.data.wheel_sections)))
                .catch((err) => console.log(err));
        },
        start({ commit, state, getters, dispatch }, wheelId) {
            if (getters.isViewing && (!state.wheel || wheelId != state.wheel.id)) {
                commit("stop")
                commit("setWheel", state.viewWheel)
                commit("setWheelSections", state.viewWheelSections)
            }
            if (!state.started) {
                BackgroundMode.enable();
                commit("setStarted", true)
                commit("setTimestamp", Math.floor(Date.now() / 1000))
                // commit("incrementCurrent", 0)
                if (!state.timeLeft) {
                    commit("initAudio");
                    dispatch("play", "voice")
                    dispatch("play", "music")
                    commit("setTime", getters.currentsection.length)
                } else {
                    commit("resumeAudio")
                }
                commit("setTimer", () => {
                    commit("decreaseTime", (Math.floor(Date.now() / 1000) - state.timestamp) * state.timerSpeed)
                    commit("setTimestamp", Math.floor(Date.now() / 1000))
                    dispatch("checkTime")
                })
            } else {
                BackgroundMode.disable();
                commit("setStarted", false)
                commit("pauseAudio")
                clearInterval(state.timer);
            }
        },
        checkTime({ state, commit, dispatch }) {
            if (!state.headsUpPlayed &&
                state.timeLeft < (state.headsUpPercent / 100) * (this.state.wheel_sections[state.currentsectionIndex] as any).length) {
                commit("setHeadsUpPlayed", true);
                dispatch("play", "headsUp");
            }
            if (state.timeLeft == 3 * state.timerSpeed) dispatch("play", "beep");
            else if (state.timeLeft <= 0) {
                commit("setTime", 0);
                dispatch("timerEnd");
            }
        },
        timerEnd({ state, getters, dispatch, commit }) {
            // Clear out timer
            commit("clearTimer")
            // Check if there are sections left
            if (state.currentsectionIndex < state.wheel_sections.length - 1) {
                commit("incrementCurrent")
                commit("setHeadsUpPlayed", false);
                if (!state.timeLeft) {
                    dispatch("play", "voice")
                    dispatch("play", "music")
                    commit("setTime", getters.currentsection.length - state.timerSpeed)
                }
                commit("setTimer", () => {
                    commit("decreaseTime", (Math.floor(Date.now() / 1000) - state.timestamp) * state.timerSpeed)
                    commit("setTimestamp", Math.floor(Date.now() / 1000))
                    dispatch("checkTime")
                })
            }
            else {
                commit("setStarted", false)
                commit("incrementCurrent", 0)
                commit("initAudio")
                dispatch("play", "wheelEnd")
            }
        },
        play({ commit, state, getters }, audio) {
            switch (audio) {
                case "voice":
                    commit("setAudio", {
                        name: audio,
                        src: `${state.audioUrl}/sections/titles/${getters.currentsection.section_id}`
                    })
                    commit("setAudioEnd", {
                        name: audio,
                        callback: () => {
                            setTimeout(() => {
                                commit("setAudio", {
                                    name: "voice",
                                    src: `${state.audioUrl}/sections/descriptions/${getters.currentsection.section_id}`
                                })
                            }, 500);
                            state.voice.onended = null;
                        }
                    })
                    break;
                case "music":
                    commit("setAudio", {
                        name: audio,
                        src: `${state.audioUrl}/music/${getters.currentsection.music_file}`
                    })
                    break;
                case "beep":
                    commit("setAudio", { name: audio, src: "assets/3_beeps.mp3" })
                    break;
                case "headsUp":
                    commit("setAudio", { name: audio, src: "assets/heads_up.mp3" })
                    break;
                case "wheelEnd":
                    commit("setAudio", { name: audio, src: "assets/wheel_complete.mp3" })
                    break;
            }
        }
    }
})

export function useStore() {
    return baseUseStore(key)
}