
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  menuController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import {
  cogOutline,
  cogSharp,
  playOutline,
  playSharp,
  pauseOutline,
  pauseSharp,
  stopOutline,
  stopSharp,
} from "ionicons/icons";
import { mapGetters, mapState, useStore } from "vuex";
import { store } from "./store/store";
export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
  },
  setup() {
    const selectedIndex = ref(0);
    const stopConfirmation = ref(false);
    const appVersion = ref("0.0.11");
    const appPages = [
      {
        title: "Prayer Wheels",
        url: "/wheels",
        iosIcon: cogOutline,
        mdIcon: cogSharp,
      },
      {
        title: "Settings",
        url: "/settings",
        iosIcon: cogOutline,
        mdIcon: cogSharp,
      },
    ];
    const route = useRoute();

    return {
      selectedIndex,
      stopConfirmation,
      appVersion,
      appPages,
      playOutline,
      playSharp,
      pauseOutline,
      pauseSharp,
      stopOutline,
      stopSharp,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
    };
  },
  computed: {
    ...mapState(["wheel", "started"]),
    ...mapGetters(["currentsection"]),
  },
  methods: {
    returnToWheel() {
      menuController.toggle();
      this.$router.push(`/wheels/${this.wheel.id}`);
    },
    async stop() {
      this.stopConfirmation = false;
      this.$nextTick(() => {
        store.commit("stop");
        this.$router.replace("/wheels");
      });
    },
  },
});
